import axios from "axios";
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getListSegments({ commit }) {
      return new Promise((resolve, reject) => {
        return axios
          .get(`segmentations`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    createSegment({ commit }, payload) {
      return new Promise((resolve, reject) => {
        return axios
          .post(`segmentations`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateSegment({ commit }, payload) {
      return new Promise((resolve, reject) => {
        return axios
          .put(`segmentations/${payload._id}`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getListGames({ commit }) {
      return new Promise((resolve, reject) => {
        return axios
          .get(`product/promofy/gamesCount`)
          .then((response) => {
            console.log("product/promofy/gamesCount", response);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    syncGames({ commit }) {
      return new Promise((resolve, reject) => {
        return axios
          .post(`/product/promofy/saveGame`)
          .then((response) => {
            console.log("response", response);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    syncTotalBetPlayers({ commit }) {
      return new Promise((resolve, reject) => {
        return axios
          .post("/product/promofy/totalBetPlayers")
          .then((response) => {
            console.log("response syncTotalBetPlayers", response);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    syncGamesManually({ commit }, payload) {
      return new Promise((resolve, reject) => {
        return axios
          .post("/product/promofy/saveGameManually", payload)
          .then((response) => {
            console.log("response", response);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateAllGamesPromotion({ commit }, payload) {
      return new Promise((resolve, reject) => {
        return axios
          .put("/product/promofy/updateAllGamesPromotion", payload)
          .then((response) => {
            console.log("response", response);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateGamePromotion({ commit }, payload) {
      return new Promise((resolve, reject) => {
        return axios
          .put("/product/promofy/updateGamePromotion", payload)
          .then((response) => {
            console.log("response", response);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
