import Vue from "vue";
import Vuex from "vuex";

// Modules
import ecommerceStoreModule from "@/views/apps/e-commerce/eCommerceStoreModule";
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";
import moduleProducts from "./products";
import usersModule from "./users";
import tagsModule from "./tags";
import brandModule from "./brands";
import gameCategoryModule from "./gamesCategory";
import customerModule from "./customers";
import synchronizeReportsModule from "./synchronizeReports";
import betcoreBanners from "./betcoreBanners";
import filesModule from "./files";
import currenciesModules from "./currencies";
import bonus from "./bonus";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    "app-ecommerce": ecommerceStoreModule,
    usersModule,
    moduleProducts,
    tagsModule,
    currenciesModules,
    brandModule,
    gameCategoryModule,
    customerModule,
    synchronizeReportsModule,
    betcoreBanners,
    filesModule,
    bonus,
  },
  strict: process.env.DEV,
});
