import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    tags: [],
    selectedTag: null,
    loading: false,
    error: null
  },
  getters: {
    getAllTags: state => state.tags,
    getActivesTags: state => state.tags.filter(tag => tag.status),
    getSelectedTag: state => state.selectedTag,
    isLoading: state => state.loading,
    hasError: state => state.error !== null
  },
  mutations: {
    SET_TAGS(state, tags) {
      state.tags = tags
    },
    SET_SELECTED_TAG(state, tag) {
      state.selectedTag = tag
    },
    SET_LOADING(state, status) {
      state.loading = status
    },
    SET_ERROR(state, error) {
      state.error = error
    },
    ADD_TAG(state, tag) {
      state.tags.push(tag)
    },
    UPDATE_TAG(state, updatedTag) {
      const index = state.tags.findIndex(tag => tag._id === updatedTag._id)
      if (index !== -1) {
        state.tags.splice(index, 1, updatedTag)
      }
    },
    REMOVE_TAG(state, tagId) {
      state.tags = state.tags.filter(tag => tag._id !== tagId)
      if (state.selectedTag && state.selectedTag._id === tagId) {
        state.selectedTag = null
      }
    }
  },
  actions: {
    // Tags CRUD
    getTags({ commit }, { status = true }) {
      commit('SET_LOADING', true)
      commit('SET_ERROR', null)
      
      return new Promise((resolve, reject) => {
        axios
          .get(`/tags?status=${status}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            commit('SET_ERROR', error)
            reject(error)
          })
          .finally(() => {
            commit('SET_LOADING', false)
          })
      })
    },

    storeTag({ commit }, formData) {
      commit('SET_LOADING', true)
      commit('SET_ERROR', null)
      
      return new Promise((resolve, reject) => {
        axios
          .post('/tags', formData)
          .then(response => {
            commit('ADD_TAG', response.data)
            resolve(response.data)
          })
          .catch(error => {
            commit('SET_ERROR', error)
            reject(error)
          })
          .finally(() => {
            commit('SET_LOADING', false)
          })
      })
    },

    updateTag({ commit }, formData) {
      commit('SET_LOADING', true)
      commit('SET_ERROR', null)
      
      return new Promise((resolve, reject) => {
        axios
          .put(`/tags/update/${formData._id}`, formData)
          .then(response => {
            commit('UPDATE_TAG', response.data)
            resolve(response.data)
          })
          .catch(error => {
            commit('SET_ERROR', error)
            reject(error)
          })
          .finally(() => {
            commit('SET_LOADING', false)
          })
      })
    },

    deleteTag({ commit }, tagId) {
      commit('SET_LOADING', true)
      commit('SET_ERROR', null)
      
      return new Promise((resolve, reject) => {
        axios
          .delete(`/tags/${tagId}`)
          .then(response => {
            commit('REMOVE_TAG', tagId)
            resolve(response.data)
          })
          .catch(error => {
            commit('SET_ERROR', error)
            reject(error)
          })
          .finally(() => {
            commit('SET_LOADING', false)
          })
      })
    },

    // Games management
    getGames(_, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/tags/games', { params })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    getGamesByTag(_, tagId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/tags/${tagId}/games`)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    addTagGames(_, { tagId, data }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/tags/${tagId}/games`, data)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    updateIndexGames(_, { tagId, data }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/tags/${tagId}/games/index`, data)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    // Providers and brands
    getProducts() {
      return new Promise((resolve, reject) => {
        axios
          .get('/tags/products')
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    // Batch operations
    addMultipleGamesToTag(_, { tagId, provider, games }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/tags/${tagId}/games/bulk`, { provider, games })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    removeAllGamesFromTag(_, { tagId }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/tags/${tagId}/games/all`)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    
    // Para gestionar estados localmente
    setSelectedTag({ commit }, tag) {
      commit('SET_SELECTED_TAG', tag)
    }
  },
}
